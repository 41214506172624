@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Karla:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Avenir:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.swiper-pagination {
  bottom: -32px !important;
}

.dark .swiper-pagination .swiper-pagination-bullet {
  background-color: #c4c4c4;
}

.light .swiper-pagination .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.54);
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: pink !important;
}

.story-content {
  flex-shrink: 1 !important;
  margin-right: 35px !important;
}

.feed-card .MuiCardHeader-action {
  margin-top: -1px !important;
}

.inline {
  display: inline !important;
}

.swiper-wrapper {
  /* padding-left: 10px; */
}
